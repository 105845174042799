// Styling for the navbar
#mainNav {
  background-color: $red-mauve-ajk;

  @include mediaQuery(tablet-desktop) {
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(25);
    transition: padding-top 0.3s,
    padding-bottom 0.3s;
    border: none;
    background-color: transparent;
  }

  .navbar-toggler {
    font-size: rem-calc(12);
    right: 0;
    padding: rem-calc(13);
    text-transform: uppercase;
    color: $white;
    border: 0;
    background-color: $orange-ajk;

    @include caviar-dream-font;
  }
  .navbar-brand {
    color: $orange-ajk;

    //&.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($yellow-ajk, 10%);
    }

    @include mediaQuery(tablet-desktop) {
      transition: all 0.3s;
    }

    &.logo-ajk {
      text-indent: -9999px;
      width: rem-calc(80);
      height: rem-calc(80);
      background: url('../images/logos/logo-final-ajk.svg') no-repeat top left;
      background-size: contain;

      @include mediaQuery(tablet-desktop) {
        width: 150px;
        height: 150px;
      }
    }
  }
  .navbar-nav {
    @include mediaQuery(tablet-end) {
      margin-top: 1em;
    }

    .nav-item {
      .nav-link {
        font-size: 90%;
        font-weight: 600;
        letter-spacing: rem-calc(1);
        color: $white;
        @include caviar-dream-font;

        //&.active,
        &:hover {
          color: $yellow-ajk;
        }

        @include mediaQuery(tablet-desktop) {
          color:$gray-800;
          padding: 1.1em 1em !important;
        }

        @include mediaQuery(desktop) {
          font-size: 75%;
        }

        @include mediaQuery(desktop-wide) {
          font-size: 85%;
        }
      }
    }
  }
  &.navbar-shrink {
    padding: .5em;
    background-color: $red-mauve-ajk;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }

    .navbar-brand {
      &.logo-ajk {
        width: rem-calc(60);
        height: rem-calc(60);
      }
    }
  }
}
