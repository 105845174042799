// Styling for the portfolio section
#portfolio {

  .portfolio-item {
    margin: 0 0 rem-calc(15);

    @include mediaQuery(mobile-end) {
      margin: 0 0 rem-calc(30);
    }

    .portfolio-card {
      border: 1px solid $gray-300;
      max-width: 300px;
      margin: 0 auto;

      @include mediaQuery(tablet) {
        max-width: 400px;
      }
    }

    .portfolio-link {
      position: relative;
      display: block;
      cursor: pointer;

      .portfolio-hover {
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all ease 0.5s;
        opacity: 0;
        background: fade-out($orange-ajk, .1);

        &:hover {
          opacity: 1;
        }

        .portfolio-hover-content {
          font-size: rem-calc(20);
          position: absolute;
          top: 50%;
          width: 100%;
          height: rem-calc(20);
          margin-top: rem-calc(-12);
          text-align: center;
          color: $white;

          i {
            margin-top: rem-calc(-12);
          }

          h3,
          h4 {
            margin: 0;
          }

        }
      }
    }
    .portfolio-caption {
      max-width: 400px;
      min-height: 155px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      text-align: center;
      background-color: $white;

      @include mediaQuery($desktop-wide) {
        min-height: 135px;
      }

      h4 {
        margin: 0;
        text-transform: none;
        font-size: rem-calc(18);
        color: $red-mauve-ajk;
        @include caviar-dream-font;
      }

      p {
        font-size: rem-calc(16);
        font-style: italic;
        margin: 0;
      }

    }
  }

  * {
    z-index: 2;
  }
}

.portfolio-modal {
  padding-right: 0px !important;

  .modal-dialog {
    margin: 1rem;
    max-width: 100vw;
  }

  .modal-content {
    text-align: center;

    h2 {
      font-size: 2em;
      margin-bottom: rem-calc(15);
      @include mediaQuery(tablet) {
        font-size: 3em;
      }
    }

    p {
      margin-bottom: rem-calc(30);
      text-align: justify;
    }

    p.item-intro {
      font-size: rem-calc(16);
      font-style: italic;
      margin: rem-calc(20) 0 rem-cal(30);
      text-align: center;
    }

    ul.list-inline {
      margin-top: 0;
      margin-bottom: rem-calc(30);
    }

    img {
      margin-bottom: rem-calc(30);
    }

    button {
      cursor: pointer;
    }
  }
}
