// Styling for the masthead
header.masthead {
  text-align: center;
  color: $white;
  height: 100vh;

  @include mediaIphone(iPhone6) {
    height: auto;
  }

  @include mediaIphone(iPhoneX) {
    height: auto;
  }

  @include mediaIphone(iPhone8) {
    height: auto;
  }

  @include mediaIphone(iPhone6Plus) {
    height: auto;
  }

  @include background-cover;

  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;

    @include mediaQuery(tablet) {
      padding-top: 190px;
      padding-bottom: 200px;
    }

    .intro-lead-in {
      font-size: rem-calc(22);
      font-style: italic;
      line-height: rem-calc(22);
      margin-bottom: rem-calc(25);

      @include mediaQuery(tablet) {
        font-size: rem-calc(30);
        line-height: rem-calc(40);
        margin-bottom: rem-calc(10);
      }
    }

    .intro-heading {
      font-size: rem-calc(20);
      font-weight: 700;
      line-height: rem-calc(50);
      margin-bottom: rem-calc(25);
      @include caviar-dream-font;

      @include mediaQuery(tablet) {
        font-size: rem-calc(25);
        line-height: rem-calc(75);
      }
    }

    h1 {
      font-size: rem-calc(30);
      margin-bottom: rem-calc(25);
      color: $black-ajk;
      @include caviar-dream-font;

      @include mediaQuery(tablet) {
        font-size: rem-calc(50);
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(50);
      }
    }
  }
}
