// Styling for the services section
.service-heading {
  margin: rem-calc(15) 0;
  text-transform: none;
  color: $orange-ajk;
  font-size: rem-calc(20);
  @include caviar-dream-font;
}

.association {
  p {
    text-align: left;
  }
}

.partenary-section {
  h4 {
    font-size: 1.3em;
    @include caviar-dream-font;
   }
}
