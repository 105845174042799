// Styling for the contact section
.contactForm {
  background-color: $gray-900;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .section-heading {
    color: $white;
  }

  .form-group {
    margin-bottom: rem-calc(25);

    input,
    textarea {
      padding: rem-calc(20);
    }

    input.form-control {
      height: auto;
    }

    textarea.form-control {
      height: 248px;
    }
  }

  .form-control:focus {
    border-color: $primary;
    box-shadow: none;
  }
}
