// Variables

// Restated Bootstrap Variables

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary: #fed136 !default;

// New colors for AJK
$orange-ajk: #e59a47 !default;
$yellow-ajk: #f9ed69 !default;
$black-ajk: #393939;
$green-ajk: #d0ef84;
$beige-ajk: #fffcef;
$blue-ajk: #52bad5;
$brown-ajk: #60544d;
$red-mauve-ajk: #594040;

// Responsive breakpoint
$responsive: true;
$mobile: 320px;
$mobile-landscape: 480px;
$tablet: 768px;
$tablet-desktop: 992px;
$desktop: 1024px;
$desktop-wide: 1200px;
// Responsive max breakpoint
$mobile-portrait-end: $mobile-landscape - 1px;
$mobile-end: $tablet - 1px;
$tablet-end: $desktop - 1px;
$desktop-end: $desktop-wide - 1px;
