// Styling for the team section
.team-member {
  margin-bottom: rem-calc(50);
  text-align: center;

  img {
    width: 225px;
    height: 225px;
    border: 7px solid $white;
  }

  h4 {
    margin-top: rem-calc(25);
    margin-bottom: 0;
    text-transform: none;
  }

  p {
    margin-top: 0;
  }
}
