.parralax-section- {

  &-dbk1,
  &-dbk2,
  &-dbk3 {
      height: 500px;
  }

  &-dbk1 {
    background: url('../images/ajk-bg/dabankou-1-parrallax.jpg') no-repeat center;
    background-size: cover;
    margin-top: 14rem;
  }

  &-dbk2 {
    background: url('../images/ajk-bg/dabankou-2-parrallax.jpg') no-repeat center;
    background-size: cover;
  }

  &-dbk3 {
    background: url('../images/ajk-bg/dabankou-3-parrallax.jpg') no-repeat center;
    background-size: cover;
    margin-top: 3rem;
  }
}

.parralax-section {
  overflow: hidden;
}