// Styling for the footer
footer {
  padding: rem-calc(25) 0;
  text-align: center;

  span.copyright {
    font-size: 80%;
    line-height: rem-calc(40);
    text-transform: none;
    @include caviar-dream-font;
  }

  ul.quicklinks {
    font-size: 90%;
    line-height: rem-calc(40);
    margin-bottom: 0;
    text-transform: none;
  }
}

ul.social-buttons {
  margin-bottom: 0;

  li {
    a {
      font-size: rem-calc(20);
      line-height: rem-calc(40);
      display: block;
      width: rem-calc(40);
      height: rem-calc(40);
      transition: all 0.3s;
      color: $white;
      border-radius: 100%;
      outline: none;
      background-color: $gray-900;

      &:active,
      &:focus,
      &:hover {
        background-color: $primary;
      }
    }
  }
}
