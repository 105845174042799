// Gestion du positionnement en CSS
.block {
    display: block;
}

.left {
    float: left;
}

.right {
    float: right
}

img.left {
    margin-right: 1rem
}

img.right {
    margin-left: 1rem
}

img.left,
img.right {
    margin-bottom: 1rem;
}

// Modification de texte
.bold,
strong {
    font-weight: bold
}

em,
.italic {
    font-style: italic
}

.w100 {
    width: 100%;
}

.wAuto {
    width: auto;
}

// Largeurs
// Classes diverses
%visuallyHidden {
    margin: 1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute;
    ;
}

.visuallyHidden {
    @extend %visuallyHidden;
}

.noUnderline {
    text-decoration: none;
}

.underline {
    text-decoration: underline;
}

.pointer {
    cursor: pointer;
}

// Clear Floated Elements
// --------------------------------------------------------------------------
.clear {
    clear: both;
}

.clearNone {
    clear: none;
}

// http://perishablepress.com/press/2008/02/05/lessons-learned-concerning-the-clearfix-css-hack
.clearfix:after {
    clear: both;
    content: ' ';
    display: block;
    font-size: 0;
    line-height: 0;
    visibility: hidden;
    width: 0;
    height: 0;
}

.clearfix {
    display: inline-block;
}

* html .clearfix {
    height: 1%;
}

.listNone {
    list-style-type: none;
    padding: 0;
}

.listTypeCircle {
    list-style-type: circle;
    padding: 0;
}

.listTypeCirclePadding {
    list-style-type: circle;
}